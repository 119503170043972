import CryptoJS from "crypto-js";

export const handleBalanceVND = (amount) => {
  const balanceFix = amount.toLocaleString("it-IT", {
    style: "currency",
    currency: "VND",
  });
  return balanceFix;
};

export const replaceStringXXX = (inputString) => {
  if (inputString.length < 8) {
    return "";
  }

  const prefix = inputString.slice(0, 4);
  const suffix = inputString.slice(-4);
  const middle = inputString.slice(4, -4).replace(/[^\s]/g, "x");

  return prefix + middle + suffix;
};

export const decryptTripleDES = (encryptedSoftpin, softpinKey) => {
  const key = CryptoJS.enc.Utf8.parse(getValidKey(softpinKey));
  const iv = CryptoJS.enc.Utf8.parse(getValidIV(softpinKey, 8));
  const decrypted = CryptoJS.TripleDES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(encryptedSoftpin) },
    key,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  ).toString(CryptoJS.enc.Utf8);
  //console.log("decryptTripleDES", key, iv, decrypted);
  return decrypted;
};

function getValidKey(key) {
  let sTemp;
  if (key.length > 24) {
    sTemp = key.substring(0, 24);
  } else {
    sTemp = key;
    while (sTemp.length !== 24) {
      sTemp = sTemp + " ";
    }
  }

  return sTemp;
}

function getValidIV(InitVector, ValidLength) {
  let sTemp;

  if (InitVector.length > ValidLength) {
    sTemp = InitVector.substring(0, ValidLength);
  } else {
    sTemp = InitVector;
    while (sTemp.length !== ValidLength) {
      sTemp = sTemp + " ";
    }
  }

  return sTemp;
}
