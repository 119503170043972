import Api from "./api";

const url = "/admin";

export const loginAdminAPI = async (props) => {
  const { username, password } = props;
  const reqUrl = `${url}/login`;
  const res = await Api.post(reqUrl, props);
  // let response = {
  //   data: {
  //     username,
  //     password,
  //   },
  // };
  // return response;
  return res.data;
};

export const logout = async () => {
  const reqUrl = `${url}/logout`;
  const res = await Api.post(reqUrl);
  return res;
};
