const routes = [
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Nhập Kho",
    icon: "ni ni-books text-orange",
    state: "nhapKho",
    views: [
      {
        path: "/nhapKho-history",
        name: "History",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/nhapKho-exelform",
        name: "Nhập Bằng Excel",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/nhapKho-form",
        name: "Nhập Bằng Form",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/nhapKho-the-giay",
        name: "Nhập Thẻ Giấy",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Quản Lý Partner",
    icon: "ni ni-atom text-green",
    state: "quanlyPartner",
    views: [
      {
        path: "/managepartner",
        name: "Danh Sách Partner",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/discountlist",
        name: "Danh Sách Chiết Khấu",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/partner-payment",
        name: "Lịch sử nạp rút",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Quản Lý Agency",
    icon: "ni ni-single-02 text-green",
    state: "quanlyAgency",
    views: [
      {
        path: "/manageagency",
        name: "Quản Lý Agency",
        miniName: "A",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Kho Mã Thẻ",
    icon: "ni ni-single-copy-04 text-yellow",
    state: "khoMaThe",
    views: [
      {
        path: "/kho-ma-the",
        name: "Kho Mã Thẻ",
        miniName: "K",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Lịch Sử Giao Dịch",
    icon: "ni ni-spaceship text-pink",
    state: "lichSuGiaoDich",
    views: [
      {
        path: "/history-transaction-card",
        name: "Mua thẻ",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/history-transaction-topup",
        name: "TOPUP",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Báo Cáo",
    icon: "ni ni-chart-pie-35 text-primary",
    state: "baoCao",
    views: [
      {
        path: "/news",
        name: "Báo Cáo",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
];

export default routes;
