// reactstrap components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
// react plugin that creates text editor
import Dropzone from "dropzone";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Api from "components/api/api";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ToastProvider from "hooks/useToastProvider";

Dropzone.autoDiscover = false;

const formSchema = Yup.object().shape({
  money: Yup.number().required("Số tiền cần nạp không được để trống"),
});

const PartnerPayment = () => {
  const params = useParams();
  const [partnerList, setPartnerList] = useState([]);
  const [partner, setPartner] = useState(0);
  const [money, setMoney] = useState(0);
  const [log, setLog] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stringAlert, setStringAlert] = useState("");

  let userAdmin = useSelector(getAdminData);

  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");
  const [partnerSearch, setPartnerSearch] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const registerRs = (fieldName, options = {}) => {
    const registeredField = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };

  const handleSubmitForm = async (value, e) => {
    e.preventDefault();
    if (partner == 0) {
      ToastProvider("error", "Vui lòng chọn Partner");
    } else {
      setIsLoading(true);
      const reqUrl = "/admin/partner-payment-submit";
      const params = {};
      params.partner_id = partner;
      params.token = userAdmin.token_login;
      params.money = money;
      params.log = log;
      const res = await Api.post(reqUrl, params);
      console.log("handleSubmitForm", res, res.data);
      if (res.data.status) {
        ToastProvider("success", res.data.message);
        setStringAlert(res.data.message);
        loadListHistoryPayment();
      } else {
        ToastProvider("error", res.data.message);
        setStringAlert(res.data.message);
        setIsLoading(false);
      }
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "partner",
      text: "Partner",
      sort: true,
    },
    {
      dataField: "money",
      text: "Tiền nạp",
      sort: true,
      formatter: (cell, row) => cell.toLocaleString(),
    },
    {
      dataField: "date_pay",
      text: "Ngày tạo",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "balance_before",
      text: "Balance trước",
      sort: true,
      formatter: (cell, row) => cell.toLocaleString(),
    },
    {
      dataField: "balance_after",
      text: "Balance sau",
      sort: true,
      formatter: (cell, row) => cell.toLocaleString(),
    },
    {
      dataField: "log",
      text: "Log",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];
  useEffect(() => {
    loadPartnerList();
    loadListHistoryPayment();
  }, []);

  useEffect(() => {
    setDataTable([]);
    loadListHistoryPayment();
  }, [pageCurrent, sizePage, fieldSort, bySort]);

  const loadPartnerList = async () => {
    console.log("loadData", params.id);
    const reqUrl = "/admin/get-list-partner";
    const paramsRequest = {
      token: userAdmin.token_login,
      page: 1,
      pageSize: 100,
      search: "",
      fieldSort: "id",
      bySort: "ASC",
    };
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("res.data.data", res.data.data);
    if (res.data.status == true) {
      let tmp = [];
      for (const it of res.data.data.data) {
        tmp.push({
          id: it.id,
          text: it.name + " (" + it.balance.toLocaleString() + " đ)",
          balance: it.balance,
        });
      }
      setPartnerList(tmp);
    }
  };

  const loadListHistoryPayment = async () => {
    console.log("partnerSearch", partnerSearch);
    const reqUrl = "/admin/get-list-history-partner-payment";
    const paramsRequest = {
      token: userAdmin.token_login,
      partner_id: partnerSearch,
      page: pageCurrent,
      pageSize: sizePage,
      start_date: startDate,
      end_date: endDate,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("loadListHistoryPayment", res.data.data);
    if (res.data.data.status == true) {
      setDataTable(res.data.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  };

  const onSearchChange = () => {
    setDataTable([]);
    loadListHistoryPayment();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  return (
    <>
      <SimpleHeader
        name={`Nạp tiền cho Partner`}
        parentName={`Nạp tiền cho Partner`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Nạp tiền cho Partner</h3>
                </CardHeader>
                <CardBody>
                  {partnerList ? (
                    <Form>
                      <FormGroup className="selected-form">
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Chọn Partner
                        </label>
                        <Select2
                          className={`form-control`}
                          options={{
                            placeholder: "Chọn Partner",
                          }}
                          value={partner}
                          onChange={(e) => setPartner(e.target.value)}
                          data={partnerList}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="quantity"
                        >
                          Số tiền nạp vào
                        </label>
                        <Input
                          {...registerRs("money")}
                          defaultValue={0}
                          name="money"
                          type="number"
                          min={0}
                          className={`${errors.money ? "isError" : ""}`}
                          onChange={(e) => setMoney(e.target.value)}
                          invalid={errors.money ? true : false}
                        />
                        <FormFeedback>{errors?.money?.message}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Log
                        </label>
                        <Input
                          defaultValue={log}
                          id="example-number-input"
                          type="textarea"
                          onChange={(e) => setLog(e.target.value)}
                        />
                      </FormGroup>
                      <div className="mt-3 d-flex d-text align-items-md-end justify-content-md-end">
                        {isLoading ? (
                          <p>{stringAlert ? stringAlert : "Đang xử lý"}</p>
                        ) : (
                          <Button
                            className="btn-icon"
                            color="primary"
                            type="button"
                            onClick={handleSubmit(handleSubmitForm)}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="ni ni-bag-17" />
                            </span>
                            <span className="btn-inner--text">Nạp</span>
                          </Button>
                        )}
                      </div>
                    </Form>
                  ) : (
                    <p>Đang tải dữ liệu</p>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    Danh sách lịch sử nạp: {totalItem} record
                  </h3>
                </CardHeader>
                <CardBody>
                  <div className="py-4 table-responsive">
                    <Row className="mb-5">
                      <Col lg="3">
                        <label
                          className="form-control-label"
                          htmlFor="start_date"
                        >
                          Chọn Partner
                        </label>
                        <Select2
                          className={`form-control`}
                          options={{
                            placeholder: "Chọn Partner",
                          }}
                          value={partnerSearch}
                          onChange={(e) => setPartnerSearch(e.target.value)}
                          data={partnerList}
                        />
                      </Col>
                      <Col lg="3">
                        <label
                          className="form-control-label"
                          htmlFor="start_date"
                        >
                          Từ ngày
                        </label>
                        <Input
                          defaultValue={startDate}
                          id="start_date"
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                      <Col lg="3">
                        <label
                          className="form-control-label"
                          htmlFor="end_date"
                        >
                          Đến ngày
                        </label>
                        <Input
                          defaultValue={endDate}
                          id="end_date"
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                      <Col lg="3">
                        <label className="form-control-label" htmlFor="btn">
                          &nbsp;
                        </label>
                        <Button
                          className="btn-icon ml-2 form-control"
                          color="primary"
                          type="button"
                          onClick={onSearchChange}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">Search</span>
                        </Button>
                      </Col>
                    </Row>
                    {dataTable != null && dataTable.length > 0 ? (
                      <BootstrapTable
                        remote
                        keyField="id"
                        data={dataTable}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        filter={filterFactory()}
                        pagination={paginationFactory({
                          page: pageCurrent,
                          sizePerPage: sizePage,
                          totalSize: totalItem,
                        })}
                        onTableChange={onTableChange}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <div className="clearAll"></div>
        </Row>
      </Container>
    </>
  );
};

export default PartnerPayment;
