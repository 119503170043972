import ReactDOM from "react-dom/client";
// react library for routing
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/css/custom.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
// core styles
import ProtectedLogin from "components/auth/ProtectedLogin";
import ProtectedLogout from "components/auth/ProtectedLogout";
import ProtectedRoute from "components/auth/ProtectedRoute";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import Alternative from "views/pages/dashboards/Alternative";
import Dashboard from "views/pages/dashboards/Dashboard";
import DiscountList from "views/pages/discountlist";
import KhoMaThe from "views/pages/khomathe";
import Login from "views/pages/login/Login";
import ManageAgency from "views/pages/manageagency";
import ManagePartner from "views/pages/managepartner";
import NhapKhoExelForm from "views/pages/nhapkho_exelform";
import NhapKhoForm from "views/pages/nhapkho_form";
import NhapKhoTheGiay from "views/pages/nhapkho_the_giay";
import NhapKhoHistory from "views/pages/nhapkho_history";
import NhapKhoHistoryDetail from "views/pages/nhapkho_history_detail";
import PartnerDetail from "views/pages/partnerdetail";
import PartnerPayment from "views/pages/partnerpayment";
import { persistor, store } from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const routes = createBrowserRouter([
  {
    path: "admin/*",
    element: <ProtectedRoute />,
    children: [
      { element: <Navigate to="/admin/dashboard" />, index: true },
      {
        path: "dashboard",
        element: <AdminLayout childrens={<Dashboard />} />,
      },
      {
        path: "alternative-dashboard",
        element: <AdminLayout childrens={<Alternative />} />,
      },
      {
        path: "nhapkho-history",
        element: <AdminLayout childrens={<NhapKhoHistory />} />,
      },
      {
        path: "nhapkho-history/detail/:id",
        element: <AdminLayout childrens={<NhapKhoHistoryDetail />} />,
      },
      {
        path: "nhapKho-exelform",
        element: <AdminLayout childrens={<NhapKhoExelForm />} />,
      },
      {
        path: "nhapKho-form",
        element: <AdminLayout childrens={<NhapKhoForm />} />,
      },
      {
        path: "nhapKho-the-giay",
        element: <AdminLayout childrens={<NhapKhoTheGiay />} />,
      },
      {
        path: "managepartner",
        element: <AdminLayout childrens={<ManagePartner />} />,
      },
      {
        path: "partnerdetail/:id",
        element: <AdminLayout childrens={<PartnerDetail />} />,
      },
      {
        path: "discountlist",
        element: <AdminLayout childrens={<DiscountList />} />,
      },
      {
        path: "partner-payment",
        element: <AdminLayout childrens={<PartnerPayment />} />,
      },
      {
        path: "manageagency",
        element: <AdminLayout childrens={<ManageAgency />} />,
      },
      {
        path: "kho-ma-the",
        element: <AdminLayout childrens={<KhoMaThe />} />,
      },
      {
        path: "history-transaction-card",
        element: <AdminLayout childrens={<KhoMaThe />} />,
      },
      {
        path: "history-transaction-topup",
        element: <AdminLayout childrens={<KhoMaThe />} />,
      },
      {
        path: "logout",
        element: <ProtectedLogout />,
      },
    ],
  },
  {
    path: "/",
    children: [
      { element: <Navigate to="/login" />, index: true },
      {
        path: "/login",
        element: (
          <AuthLayout childrens={<ProtectedLogin props={<Login />} />} />
        ),
      },
    ],
  },
]);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer limit={3} />
      <RouterProvider router={routes} />
    </PersistGate>
  </Provider>
);
