import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Button,
} from "reactstrap";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Api from "components/api/api";

const DiscountList = () => {
  const [data, setData] = useState(null);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [textSearch, setTextSearch] = useState("");
  const [telco, setTelco] = useState(0);
  const [service, setService] = useState(0);
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");
  const [partnerList, setPartnerList] = useState([]);
  const [partner, setPartner] = useState(0);
  let userAdmin = useSelector(getAdminData);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "partner",
      text: "Partner",
      sort: true,
    },
    {
      dataField: "telco",
      text: "Telco",
      sort: true,
    },
    {
      dataField: "service",
      text: "Dịch vụ",
      sort: true,
    },
    {
      dataField: "menh_gia",
      text: "Mệnh giá",
      sort: true,
    },
    {
      dataField: "chietkhau",
      text: "CK",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Ngày tạo",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "status",
      text: "Trạng thái",
      sort: true,
      formatter: (cell) => (
        <Button
          className="btn-icon d-flex"
          size="sm"
          color="info"
          type="button"
          outline
        >
          <span className="btn-inner--text">
            {cell == 1 ? "Active" : "Pending"}
          </span>
        </Button>
      ),
    },
  ];
  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];
  useEffect(() => {
    loadData();
    loadPartnerList();
  }, []);

  useEffect(() => {
    setData(null);
    loadData();
  }, [pageCurrent, sizePage, fieldSort, bySort, textSearch]);

  const loadData = async () => {
    const reqUrl = "/admin/get-list-chiet-khau-partner";
    const paramsRequest = {
      token: userAdmin.token_login,
      page: pageCurrent,
      pageSize: sizePage,
      search: textSearch,
      fieldSort: fieldSort,
      bySort: bySort,
      partner_id: partner,
      telco_id: telco,
      service_id: service,
    };
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("loadData", res.data.data);
    if (res.data.data.status == true) {
      setData(res.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  };

  const onSearchChange = () => {
    setData(null);
    loadData();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  const loadPartnerList = async () => {
    const reqUrl = "/admin/get-list-partner";
    const paramsRequest = {
      token: userAdmin.token_login,
      page: 1,
      pageSize: 100,
      search: "",
      fieldSort: "id",
      bySort: "ASC",
    };
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("res.data.data", res.data.data);
    if (res.data.status == true) {
      let tmp = [];
      for (const it of res.data.data.data) {
        tmp.push({
          id: it.id,
          text: it.name + " (" + it.balance.toLocaleString() + " đ)",
          balance: it.balance,
        });
      }
      setPartnerList(tmp);
    }
  };

  return (
    <>
      <SimpleHeader name="Discount List" parentName="Discount List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  Danh sách chiết khấu: {data ? data.pagination.total : 0}{" "}
                  record
                </h3>
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <Row className="mb-5">
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Chọn Partner
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Partner",
                        }}
                        value={partner}
                        onChange={(e) => setPartner(e.target.value)}
                        data={partnerList}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Loại dịch vụ
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Dịch vụ",
                        }}
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                        defaultValue={service}
                        data={[
                          { id: 0, text: "Tất cả" },
                          { id: 1, text: "Thẻ Cào" },
                          { id: 2, text: "TOPUP" },
                        ]}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="end_date">
                        Nhà mạng
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn nhà mạng",
                        }}
                        value={telco}
                        onChange={(e) => setTelco(e.target.value)}
                        defaultValue={telco}
                        data={[
                          { id: 0, text: "Tất cả" },
                          { id: 1, text: "Mobifone" },
                          { id: 2, text: "Viettel" },
                          { id: 3, text: "Vinaphone" },
                        ]}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="btn">
                        &nbsp;
                      </label>
                      <Button
                        className="btn-icon ml-2 form-control"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </Col>
                  </Row>
                  {data != null && data.data.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.data}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DiscountList;
