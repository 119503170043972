import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_HOT_API,
});

class Api {
  static async get(url, params = {}, data = {}) {
    const response = await client.get(url, {
      params,
      headers: await this.getHeaders(),
    });
    return response;
  }

  static async post(url, params = {}) {
    const config = {
      headers: await this.getHeaders(),
    };

    const response = await client.post(url, params, config);
    return response;
  }

  // to be updated
  static async update(url, params = {}) {
    const config = {
      headers: await this.getHeaders(),
      // validateStatus,
    };

    const response = await client.put(url, params, config);
    return response;
  }

  // to be delete
  static async delete() {}

  static async getHeaders(contentType = "application/json; charset=utf-8") {
    return {
      //"Content-Type": contentType,
      "Content-Type": "multipart/form-data",
      Authorization: this.getToken(),
    };
  }

  static getToken() {
    return "Bearer " + process.env.REACT_APP_API_KEY;
  }
}

export default Api;
