// reactstrap components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Button
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
// react plugin that creates text editor
import Dropzone from "dropzone";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { handleBalanceVND } from "utils/helper";
import Api from "components/api/api";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";
import { decryptTripleDES } from "utils/helper";
import { replaceStringXXX } from "utils/helper";

Dropzone.autoDiscover = false;

const NhapKhoHistoryDetail = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [store_cards, setStoreCards] = useState(null);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [textSearchTmp, setTextSearchTmp] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");
  let userAdmin = useSelector(getAdminData);

  const columns = [{
      dataField: 'id',
      text: 'ID',
      sort: true,
    }, {
      dataField: 'agency',
      text: 'Agency',
      sort: true,
    }, {
      dataField: 'telco',
      text: 'Telco',
      sort: true,
    }, {
      dataField: 'menh_gia',
      text: 'Mệnh giá',
      sort: true,
    }, {
      dataField: 'code',
      text: 'Code',
      sort: true,
      formatter:(cell, row)=>{
        return replaceStringXXX(decryptTripleDES(cell, process.env.REACT_APP_KEY_PRIVATE_DT))
      }
    }, {
      dataField: 'serial',
      text: 'Serial',
      sort: true,
      formatter:(cell, row)=>{
        return replaceStringXXX(decryptTripleDES(cell, process.env.REACT_APP_KEY_PRIVATE_DT))
      }
    }, {
      dataField: 'exprired_date',
      text: 'Hết hạn',
      sort: true,
      formatter: (cell, row) => (
        `${(new Date(cell)).toLocaleString('fr-BE')}`
      )
    }, {
      dataField: 'chietkhau_admin',
      text: 'CK',
      sort: true,
    }, {
      dataField: 'date_buy',
      text: 'Ngày mua',
      sort: true,
      formatter: (cell, row) => (
        `${(new Date(cell)).toLocaleString('fr-BE')}`
      )
    }, {
      dataField: 'date_sell',
      text: 'Ngày bán',
      sort: true,
      formatter: (cell, row) => (
        cell ? `${(new Date(cell)).toLocaleString('fr-BE')}` : ''
      )
    }
  ]
  const defaultSorted = [{
    dataField: fieldSort,
    order: bySort
  }];
  useEffect(()=>{
    loadData();
    loadCards();
  }, [params.id]);

  useEffect(()=>{
    setStoreCards(null);
    loadCards();
  }, [pageCurrent, sizePage, fieldSort, bySort, textSearch]);

  const loadData = async ()=>{
    console.log("loadData hihi");
    console.log("loadData", params.id);
    const reqUrl = "/admin/nhap-kho-history-detail";
    const paramsRequest = {token:userAdmin.token_login, order_id:params.id};
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("res.data.data", res.data.data);
    if(res.data.status == true)
    {
      setData(res.data.data);
    }
  }

  const loadCards = async ()=>{
    const reqUrl = "/admin/get-list-card-for-order";
    const paramsRequest = {token:userAdmin.token_login, order_id:params.id, page:pageCurrent, pageSize:sizePage, search:textSearch, fieldSort:fieldSort, bySort:bySort};
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("loadCards", res.data.data);
    if(res.data.data.status == true)
    {
      setStoreCards(res.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  }

  const onSearchChange = () =>{
    console.log("onSearchChange", textSearchTmp);
    setTextSearch(textSearchTmp);
  }

  const onTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log("onTableChange", type, page, sizePage, filters, sortField, sortOrder);
    if(type == "pagination")
    {
      setPageCurrent(page);
      setSizePage(sizePage);
    }
    else if(type == "sort")
    {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  }

  return (
    <>
      <SimpleHeader
        name={`Thông tin chi tiết History: #${params.id}`}
        parentName={`Thông tin chi tiết History: #${params.id}`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    Thông tin chi tiết History: {params.id}
                  </h3>
                </CardHeader>
                <CardBody>
                {data ? (
                  <Form>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Tên
                      </label>
                      <Input
                        defaultValue={data.name}
                        id="example-number-input"
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Order ID
                      </label>
                      <Input
                        defaultValue={data.order_id}
                        id="example-number-input"
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Thời gian mua
                      </label>
                      <Input
                        defaultValue={data.time_buy.toLocaleString('fr-BE')}
                        id="example-number-input"
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Agency
                      </label>
                      <Input
                        defaultValue={data.agency.name}
                        id="example-number-input"
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Số lượng thẻ mua
                      </label>
                      <Input
                        defaultValue={(data.so_luong_the || 0).toLocaleString()}
                        id="example-number-input"
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Số tiền mua
                      </label>
                      <Input
                        defaultValue={handleBalanceVND(data.so_tien || 0)}
                        id="example-number-input"
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Log Data nhập vào
                      </label>
                      <Input
                        defaultValue={data.log_input}
                        id="example-number-input"
                        type="textarea"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Log Data trả về
                      </label>
                      <Input
                        defaultValue={data.log_response}
                        id="example-number-input"
                        type="textarea"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Log Data gửi cho API Agency
                      </label>
                      <Input
                        defaultValue={data.log_data_send}
                        id="example-number-input"
                        type="textarea"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Log Data callback từ API Agency
                      </label>
                      <Input
                        defaultValue={data.log_callback}
                        id="example-number-input"
                        type="textarea"
                        readOnly
                      />
                    </FormGroup>
                  </Form>
                  )
                  :
                  (
                    <p>Đang tải dữ liệu</p>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
        <Col lg="12">
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  Danh sách card đã nhập: {store_cards ? store_cards.pagination.total : 0}
                </h3>
              </CardHeader>
              <CardBody>
                      <div className="py-4 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <Input
                              defaultValue=""
                              name="search"
                              type="text"
                              onChange={(e) => setTextSearchTmp(e.target.value)}
                            />
                            <Button
                              className="btn-icon ml-2"
                              color="primary"
                              type="button"
                              onClick={onSearchChange}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="ni ni-bag-17" />
                              </span>
                              <span className="btn-inner--text">Search</span>
                            </Button>
                          </label>
                        </div>
                        {store_cards != null && store_cards.data.length > 0 ? (
                        <BootstrapTable
                          remote
                          keyField="id"
                          data={ store_cards.data }
                          columns={ columns }
                          defaultSorted={ defaultSorted }
                          filter={ filterFactory() }
                          pagination={ paginationFactory({page: pageCurrent, sizePerPage: sizePage, totalSize: totalItem }) }
                          onTableChange={ onTableChange }
                        />
                        ) : (
                        <></>
                        )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <div className="clearAll"></div>
          </Row>
      </Container>
    </>
  );
};

export default NhapKhoHistoryDetail;
