import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Button,
  Input,
} from "reactstrap";
import { useEffect, useState } from "react";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";
import Api from "components/api/api";

const ManageAgency = () => {
  let userAdmin = useSelector(getAdminData);
  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [textSearchTmp, setTextSearchTmp] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "partner_id",
      text: "Partner ID",
      sort: true,
    },
    {
      dataField: "partner_key",
      text: "Partner Key",
      sort: true,
    },
    {
      dataField: "balance",
      text: "Balance",
      sort: true,
      formatter: (cell) => cell.toLocaleString(),
    },
    {
      dataField: "createdAt",
      text: "Ngày tham gia",
      sort: true,
      formatter: (cell) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell) => (
        <Button
          className="btn-icon d-flex"
          size="sm"
          color="info"
          type="button"
          outline
        >
          <span className="btn-inner--text">
            {cell == 1 ? "Active" : "Pending"}
          </span>
        </Button>
      ),
    },
    // {
    //   dataField: "id",
    //   text: "Action",
    //   formatter: (cell, row) => (
    //     <UncontrolledDropdown>
    //       <DropdownToggle
    //         className="btn-icon-only text-light"
    //         color=""
    //         role="button"
    //         size="sm"
    //       >
    //         <i className="fas fa-ellipsis-v" />
    //       </DropdownToggle>
    //       <DropdownMenu className="dropdown-menu-arrow" end>
    //         <DropdownItem
    //           href={`/admin/partnerdetail/${cell}`}>
    //           Edit
    //         </DropdownItem>
    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   ),
    // },
  ];

  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];

  useEffect(() => {
    getListHistory();
  }, []);

  useEffect(() => {
    setDataTable([]);
    getListHistory();
  }, [pageCurrent, sizePage, fieldSort, bySort, textSearch]);

  const getListHistory = async () => {
    const reqUrl = "/admin/get-list-agency";
    const params = {
      token: userAdmin.token_login,
      page: pageCurrent,
      pageSize: sizePage,
      search: textSearch,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("res.data.data", res.data.data);
      setDataTable(res.data.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  };

  const onSearchChange = () => {
    console.log("onSearchChange", textSearchTmp);
    setTextSearch(textSearchTmp);
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  return (
    <>
      <SimpleHeader name="Manage Partner" parentName="Manage Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Danh Sách Partner</h3>
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 pb-1"
                  >
                    <label>
                      Search:
                      <Input
                        defaultValue=""
                        name="search"
                        type="text"
                        onChange={(e) => setTextSearchTmp(e.target.value)}
                      />
                      <Button
                        className="btn-icon ml-2"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </label>
                  </div>
                  {dataTable != null && dataTable.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ManageAgency;
