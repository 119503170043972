// reactstrap components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  FormFeedback,
} from "reactstrap";
// react plugin that creates text editor
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "dropzone";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";
import Api from "components/api/api";
import ToastProvider from "hooks/useToastProvider";

Dropzone.autoDiscover = false;

const formSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("File không được để trống")
    .test("fileFormat", "Chỉ được chọn file excel", (value) => {
      return [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ].includes(value[0]?.type);
    }),
});

const NhapKhoExelForm = () => {
  const [valueSelect, setValueSelect] = useState(1);
  const [agencyList, setAgencyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stringAlert, setStringAlert] = useState("");
  let userAdmin = useSelector(getAdminData);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  useEffect(()=>{
    getListAgency();
  }, []);

  const registerRs = (fieldName, options = {}) => {
    const registeredField = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };

  const handleSubmitForm = async (value, e) => {
    e.preventDefault();
    setIsLoading(true);
    const reqUrl = "/admin/nhap-kho-card-file-excel";
    const formData = new FormData();
    formData.append('file',value.file[0]);
    formData.append('agency_id',valueSelect);
    formData.append('token',userAdmin.token_login);
    const res = await Api.post(reqUrl, formData);
    if(res.data.status)
    {
      ToastProvider("success", "Nhập kho thành công");
      setStringAlert(`Nhập kho thành công: Tổng: ${res.data.data.length}, Lưu ${res.data.count_done}, Trùng ${res.data.count_fail}`);
    }
    else
    {
      ToastProvider("error", res.data.message);
      setStringAlert(res.data.message);
      setIsLoading(false);
    }
  };

  const getListAgency = async ()=>{
    const reqUrl = "/admin/get-list-agency";
    const params = {token:userAdmin.token_login};
    const res = await Api.post(reqUrl, params);
    if(res.data.status == true)
    {
      let tmp = [];
      for(const it of res.data.data.data)
      {
        tmp.push({id:it.id, text:it.name + " (" + it.balance.toLocaleString() + " đ)"});
      }
      setAgencyList(tmp);
    }
  }

  return (
    <>
      <SimpleHeader name="Exel Form" parentName="ExelForm" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Nhập kho bằng Excel</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/* --------------SelectBox--------- */}
                    <FormGroup className="selected-form">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn Agency
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Agency",
                        }}
                        value={valueSelect}
                        onChange={(e) => setValueSelect(e.target.value)}
                        defaultValue={1}
                        data={agencyList}
                      />
                      <FormFeedback>{errors?.selectBox?.message}</FormFeedback>
                    </FormGroup>
                    {/* --------------Choose File--------- */}
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn File Excel
                      </label>
                      <div className={`custom-file`}>
                        <input
                          {...register("file")}
                          className="custom-file-input"
                          id="customFileLang"
                          lang="en"
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        <label
                          className={`custom-file-label ${
                            errors.file ? "isError" : ""
                          }`}
                          htmlFor="customFileLang"
                        >
                          Select file
                        </label>
                      </div>
                      <FormFeedback>{errors?.file?.message}</FormFeedback>
                    </FormGroup>
                    <div className="mt-3 d-flex d-text align-items-md-end justify-content-md-end">
                      {isLoading ? (
                        <p>{stringAlert ? stringAlert : "Đang xử lý"}</p>
                      ) : (
                        <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        onClick={handleSubmit(handleSubmitForm)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Nhập kho</span>
                      </Button>
                      )}
                      
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NhapKhoExelForm;
