import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Button,
  Input,
  Col,
  CardTitle,
} from "reactstrap";
import { useEffect, useState } from "react";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";
import Api from "components/api/api";
import { replaceStringXXX } from "utils/helper";
import { decryptTripleDES } from "utils/helper";

const KhoMaThe = () => {
  let userAdmin = useSelector(getAdminData);
  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [textSearchTmp, setTextSearchTmp] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");
  const [agencyList, setAgencyList] = useState([]);
  const [agency, setAgency] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [telco, setTelco] = useState(0);
  const [status, setStatus] = useState(-1);
  const [summary, setSummary] = useState({
    tong_card: 0,
    tong_card_ban: 0,
    tong_card_sap_het_han: 0,
    tong_card_het_han: 0,
  });

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "agency",
      text: "Agency",
      sort: true,
    },
    {
      dataField: "telco",
      text: "Telco",
      sort: true,
    },
    {
      dataField: "menh_gia",
      text: "Mệnh giá",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      formatter: (cell, row) => {
        return replaceStringXXX(
          decryptTripleDES(cell, process.env.REACT_APP_KEY_PRIVATE_DT)
        );
      },
    },
    {
      dataField: "serial",
      text: "Serial",
      sort: true,
      formatter: (cell, row) => {
        return replaceStringXXX(
          decryptTripleDES(cell, process.env.REACT_APP_KEY_PRIVATE_DT)
        );
      },
    },
    {
      dataField: "exprired_date",
      text: "Hết hạn",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "chietkhau_admin",
      text: "CK",
      sort: true,
    },
    {
      dataField: "date_buy",
      text: "Ngày mua",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "date_sell",
      text: "Ngày bán",
      sort: true,
      formatter: (cell, row) =>
        cell ? `${new Date(cell).toLocaleString("fr-BE")}` : "",
    },
  ];

  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];

  useEffect(() => {
    getListHistory();
    getListAgency();
  }, []);

  useEffect(() => {
    setTotalItem(0);
    setDataTable([]);
    getListHistory();
  }, [pageCurrent, sizePage, fieldSort, bySort]);

  const getListAgency = async () => {
    const reqUrl = "/admin/get-list-agency";
    const params = { token: userAdmin.token_login };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      let tmp = [];
      for (const it of res.data.data.data) {
        tmp.push({
          id: it.id,
          text: it.name + " (" + it.balance.toLocaleString() + " đ)",
          balance: it.balance,
        });
      }
      setAgencyList(tmp);
    }
  };

  const getListHistory = async () => {
    const reqUrl = "/admin/get-all-list-card";
    const params = {
      token: userAdmin.token_login,
      page: pageCurrent,
      pageSize: sizePage,
      search: textSearch,
      agency_id: agency,
      telco_id: telco,
      status: status,
      start_date: startDate,
      end_date: endDate,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("res.data.data", res.data.data);
      setDataTable(res.data.data.data ? res.data.data.data : []);
      setTotalItem(
        res.data.data.pagination ? res.data.data.pagination.total : 0
      );
      if (pageCurrent == 1) {
        setSummary(res.data.summary);
      }
    }
  };

  const onSearchChange = () => {
    setTotalItem(0);
    setDataTable([]);
    getListHistory();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  return (
    <>
      <SimpleHeader name="Kho mã thẻ" parentName="Kho mã thẻ" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  Kho mã thẻ: {summary.tong_card.toLocaleString()} thẻ
                </h3>
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <Row className="mb-5">
                    <Col md="6" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng số thẻ đã bán
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_card_ban.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng thẻ tồn kho
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {(
                                  summary.tong_card - summary.tong_card_ban
                                ).toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Thẻ sắp hết hạn 10 ngày
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_card_sap_het_han.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="3">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Thẻ sắp hết hạn
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_card_het_han.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col lg="2">
                      <label
                        className="form-control-label"
                        htmlFor="start_date"
                      >
                        Chọn Agency
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Partner",
                        }}
                        value={agency}
                        onChange={(e) => setAgency(e.target.value)}
                        data={agencyList}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Nhà mạng
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn nhà mạng",
                        }}
                        value={telco}
                        onChange={(e) => setTelco(e.target.value)}
                        defaultValue={telco}
                        data={[
                          { id: 0, text: "Tất cả" },
                          { id: 1, text: "Mobifone" },
                          { id: 2, text: "Viettel" },
                          { id: 3, text: "Vinaphone" },
                        ]}
                      />
                    </Col>
                    <Col lg="2">
                      <label
                        className="form-control-label"
                        htmlFor="start_date"
                      >
                        Mua Từ ngày
                      </label>
                      <Input
                        defaultValue={startDate}
                        id="start_date"
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Mua Đến ngày
                      </label>
                      <Input
                        defaultValue={endDate}
                        id="end_date"
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Trạng thái
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Status",
                        }}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={status}
                        data={[
                          { id: -1, text: "Tất cả" },
                          { id: 0, text: "Đang giữ" },
                          { id: 1, text: "Chưa bán" },
                          { id: 2, text: "Đã bán" },
                        ]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Code hoặc Serial
                      </label>
                      <Input
                        defaultValue=""
                        name="search"
                        type="text"
                        onChange={(e) => setTextSearch(e.target.value)}
                      />
                    </Col>
                    <Col lg="3">
                      <label className="form-control-label" htmlFor="btn">
                        &nbsp;
                      </label>
                      <Button
                        className="btn-icon ml-2 form-control"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </Col>
                  </Row>
                  {dataTable != null && dataTable.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default KhoMaThe;
