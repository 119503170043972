// reactstrap components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
// react plugin that creates text editor
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "dropzone";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import ToastProvider from "hooks/useToastProvider";

Dropzone.autoDiscover = false;

const formSchema = Yup.object().shape({
  quantity: Yup.number()
    .required("Số lượng cần mua không được để trống")
    .min(1, "Số lượng ít nhất là 1"),
});

const NhapKhoForm = () => {
  const [valueAgency, setValueAgency] = useState(0);
  const [valuePrice, setValuePrice] = useState(0);
  const [valueNetworkOperator, setValueNetworkOperator] = useState("Viettel");
  const [agencyList, setAgencyList] = useState([]);
  const [telcoList, setTelcoList] = useState([]);
  const [menhGiaList, setMenhGiaList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stringAlert, setStringAlert] = useState("");
  const [product, setProduct] = useState([]);
  const [soTienCanMua, setSoTienCanMua] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [contentCard, setContentCard] = useState("");

  let userAdmin = useSelector(getAdminData);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const registerRs = (fieldName, options = {}) => {
    const registeredField = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };

  const handleSubmitForm = async (value, e) => {
    if (e) e.preventDefault();

    let code = "";
    let serial = "";

    if (valueAgency == 0) {
      ToastProvider("error", "Vui lòng chọn Agency");
    } else if (valueNetworkOperator == 0) {
      ToastProvider("error", "Vui lòng chọn nhà mạng");
    } else if (valuePrice == 0) {
      ToastProvider("error", "Vui lòng chọn mệnh giá");
    } else {
      let isPass = true;
      if (valueAgency == 1) {
        if (contentCard == "") {
          isPass = false;
          ToastProvider("error", "Vui lòng nhập thẻ");
        } else {
          const tmp = contentCard.split(/\n/);
          if (tmp.length == 2) {
            code = tmp[0].replace("Mã thẻ: ", "");
            serial = tmp[1].replace("Số seri: ", "");

            if (code == "" || serial == "") {
              isPass = false;
              ToastProvider("error", "Không lấy được mã thẻ và serial");
            } else {
              console.log(code, serial);
            }
          } else {
            isPass = false;
            ToastProvider("error", "Cấu trúc không đúng");
          }
        }
      }

      if (isPass) {
        try {
          console.log(
            "handleSubmitForm",
            valueAgency,
            valueNetworkOperator,
            valuePrice,
            quantity
          );
          setIsLoading(true);
          setStringAlert("Đang xử lý");
          const reqUrl = "/admin/nhap-kho-card-api";
          const params = {};
          params.agency_id = valueAgency;
          params.telco_id = valueNetworkOperator;
          params.menh_gia_id = valuePrice;
          params.amount = quantity;
          params.code = code;
          params.serial = serial;
          const res = await Api.post(reqUrl, params);
          console.log("handleSubmitForm", res, res.data);
          if (res.data.status) {
            ToastProvider("success", "Nhập kho thành công");
            setStringAlert(
              `Nhập kho thành công: Tổng: ${res.data.data.length}, Lưu ${res.data.count_done}, Trùng ${res.data.count_fail}`
            );
            setContentCard("");
            setIsLoading(false);
          } else {
            ToastProvider("error", res.data.message);
            setStringAlert(res.data.message);
            setIsLoading(false);
          }
        } catch (error) {
          ToastProvider("error", "Có lỗi xảy ra vui lòng thử lại");
          setStringAlert("Có lỗi xảy ra vui lòng thử lại");
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    getListAgency();
  }, []);

  useEffect(() => {
    getListProductServiceAgency();
    setValuePrice(0);
    setValueNetworkOperator(0);
  }, [valueAgency]);

  useEffect(() => {
    let money = 0;
    if (quantity > 0 && valuePrice > 0) {
      let menh_gia = null;
      console.log(product[valueNetworkOperator].menh_gia);
      for (let i = 0; i < product[valueNetworkOperator].menh_gia.length; i++) {
        if (product[valueNetworkOperator].menh_gia[i].id == valuePrice) {
          menh_gia = product[valueNetworkOperator].menh_gia[i];
          break;
        }
      }

      if (menh_gia) {
        money =
          (menh_gia.money - menh_gia.money * (menh_gia.chietkhau_admin / 100)) *
          quantity;
      }
    }

    let agency = null;
    for (let i = 0; i < agencyList.length; i++) {
      if (agencyList[i].id == valueAgency) {
        agency = agencyList[i];
        break;
      }
    }
    if (agency) {
      if (money < agency.balance) {
        setIsLoading(false);
      } else {
        ToastProvider("error", "Số tiền của bạn không đủ");
        setStringAlert("Số tiền của bạn không đủ");
        setIsLoading(true);
      }
    }
    setSoTienCanMua(money);
  }, [valueAgency, valueNetworkOperator, valuePrice, quantity]);

  useEffect(() => {
    let menhgia = [];
    if (product[valueNetworkOperator] != undefined) {
      for (const tmp of product[valueNetworkOperator].menh_gia) {
        if (tmp != undefined) {
          menhgia.push({
            id: tmp.id,
            text: tmp.name + " (" + tmp.chietkhau_admin + "%)",
          });
        }
      }
      setValuePrice(0);
      setMenhGiaList(menhgia);
    }
  }, [valueNetworkOperator]);

  const getListAgency = async () => {
    const reqUrl = "/admin/get-list-agency";
    const params = { token: userAdmin.token_login };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      let tmp = [];
      for (const it of res.data.data.data) {
        tmp.push({
          id: it.id,
          text: it.name + " (" + it.balance.toLocaleString() + " đ)",
          balance: it.balance,
        });
      }
      setAgencyList(tmp);
    }
  };

  const getListProductServiceAgency = async () => {
    const reqUrl = "/admin/get-list-product-agency-service";
    const params = {
      token: userAdmin.token_login,
      agency_id: valueAgency,
      service_id: 1,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      let tmp = [];
      for (const it of res.data.data) {
        if (!tmp[it.telco.id]) {
          tmp[it.telco.id] = it.telco;
          tmp[it.telco.id].menh_gia = [it.menh_gia];
          tmp[it.telco.id].menh_gia[0].chietkhau_admin = it.chietkhau_admin;
        } else {
          let isHas = false;
          for (let i = 0; i < tmp[it.telco.id].menh_gia.length; i++) {
            if (tmp[it.telco.id].menh_gia[i].id == it.menh_gia.id) {
              isHas = true;
              break;
            }
          }

          if (!isHas) {
            tmp[it.telco.id].menh_gia.push({
              ...it.menh_gia,
              chietkhau_admin: it.chietkhau_admin,
            });
          }
        }
      }
      let telco = [];
      for (const tmp1 of tmp) {
        if (tmp1 != undefined) {
          telco.push({ id: tmp1.id, text: tmp1.name });
        }
      }
      setTelcoList(telco);
      setProduct(tmp);
    }
  };

  return (
    <>
      <SimpleHeader name="Main Form" parentName="Main Form" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Nhập Kho Bằng Form</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/* --------------SelectBox Agency--------- */}
                    <FormGroup className="selected-form">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn Agency
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Agency",
                        }}
                        value={valueAgency}
                        onChange={(e) => setValueAgency(e.target.value)}
                        data={agencyList}
                      />
                      <FormFeedback>{errors?.selectBox?.message}</FormFeedback>
                    </FormGroup>
                    {/* --------------SelectBox Nhà Mạng--------- */}
                    <FormGroup className="selected-form">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn Nhà Mạng
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Nhà Mạng",
                        }}
                        value={valueNetworkOperator}
                        onChange={(e) =>
                          setValueNetworkOperator(e.target.value)
                        }
                        data={telcoList}
                      />
                      <FormFeedback>
                        {errors?.selectNetworkOperator?.message}
                      </FormFeedback>
                    </FormGroup>
                    {/* --------------SelectBox Mệnh Giá--------- */}
                    <FormGroup className="selected-form">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn Mệnh Giá
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Mệnh Giá",
                        }}
                        value={valuePrice}
                        onChange={(e) => setValuePrice(e.target.value)}
                        data={menhGiaList}
                      />
                      <FormFeedback>{errors?.selectBox?.message}</FormFeedback>
                    </FormGroup>
                    {/* ---------------------Input Text-------------- */}
                    {valueAgency == 1 ? (
                      <>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="content_card"
                          >
                            Nội dung Thẻ
                          </label>
                          <Input
                            {...registerRs("content_card")}
                            value={contentCard}
                            name="content_card"
                            type="textarea"
                            className={`${
                              errors.content_card ? "isError" : ""
                            }`}
                            onChange={(e) => setContentCard(e.target.value)}
                            invalid={errors.content_card ? true : false}
                          />
                          <FormFeedback>
                            {errors?.content_card?.message}
                          </FormFeedback>
                        </FormGroup>
                      </>
                    ) : (
                      <>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="quantity"
                          >
                            Số Lượng Cần Mua
                          </label>
                          <Input
                            {...registerRs("quantity")}
                            defaultValue={0}
                            name="quantity"
                            type="number"
                            min={0}
                            className={`${errors.quantity ? "isError" : ""}`}
                            onChange={(e) => setQuantity(e.target.value)}
                            invalid={errors.quantity ? true : false}
                          />
                          <FormFeedback>
                            {errors?.quantity?.message}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="quantity"
                          >
                            Số tiền cần mua
                          </label>
                          <Input
                            value={soTienCanMua.toLocaleString()}
                            readOnly
                            type="text"
                          />
                        </FormGroup>
                      </>
                    )}
                    <div className="mt-3 d-flex d-text align-items-md-end justify-content-md-end">
                      <p>{stringAlert ? stringAlert : ""}</p>
                    </div>
                    <div className="mt-3 d-flex d-text align-items-md-end justify-content-md-end">
                      {isLoading ? (
                        <></>
                      ) : valueAgency == 1 ? (
                        <Button
                          className="btn-icon"
                          color="primary"
                          type="button"
                          onClick={handleSubmitForm}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">Nhập kho</span>
                        </Button>
                      ) : (
                        <Button
                          className="btn-icon"
                          color="primary"
                          type="button"
                          onClick={handleSubmit(handleSubmitForm)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">Nhập kho</span>
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NhapKhoForm;
