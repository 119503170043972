// reactstrap components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Button,
} from "reactstrap";
import Select2 from "react-select2-wrapper";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
// react plugin that creates text editor
import Dropzone from "dropzone";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Api from "components/api/api";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";

Dropzone.autoDiscover = false;

const PartnerDetail = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [chiet_khau, setChietKhau] = useState(null);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [textSearchTmp, setTextSearchTmp] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");
  const [telco, setTelco] = useState(0);
  const [service, setService] = useState(0);
  let userAdmin = useSelector(getAdminData);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "partner",
      text: "Partner",
      sort: true,
    },
    {
      dataField: "telco",
      text: "Telco",
      sort: true,
    },
    {
      dataField: "service",
      text: "Dịch vụ",
      sort: true,
    },
    {
      dataField: "menh_gia",
      text: "Mệnh giá",
      sort: true,
    },
    {
      dataField: "chietkhau",
      text: "CK",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Ngày tạo",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "status",
      text: "Trạng thái",
      sort: true,
      formatter: (cell) => (
        <Button
          className="btn-icon d-flex"
          size="sm"
          color="info"
          type="button"
          outline
        >
          <span className="btn-inner--text">
            {cell == 1 ? "Active" : "Pending"}
          </span>
        </Button>
      ),
    },
  ];
  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];
  useEffect(() => {
    loadData();
    loadListChietKhau();
  }, [params.id]);

  useEffect(() => {
    setChietKhau(null);
    loadListChietKhau();
  }, [pageCurrent, sizePage, fieldSort, bySort, textSearch]);

  const loadData = async () => {
    console.log("loadData", params.id);
    const reqUrl = "/admin/get-detail-partner";
    const paramsRequest = {
      token: userAdmin.token_login,
      partner_id: params.id,
    };
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("res.data.data", res.data.data);
    if (res.data.status == true) {
      setData(res.data.data);
    }
  };

  const loadListChietKhau = async () => {
    const reqUrl = "/admin/get-list-chiet-khau-partner";
    const paramsRequest = {
      token: userAdmin.token_login,
      partner_id: params.id,
      telco_id: telco,
      service_id: service,
      page: pageCurrent,
      pageSize: sizePage,
      search: textSearch,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("loadListChietKhau", res.data.data);
    if (res.data.data.status == true) {
      setChietKhau(res.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  };

  const onSearchChange = () => {
    setChietKhau(null);
    loadListChietKhau();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  const onDuplicateChetKhau = async () => {
    const reqUrl = "/admin/tao-chiet-khau-cho-partner";
    const paramsRequest = {
      token: userAdmin.token_login,
      partner_id_dich: params.id,
    };
    const res = await Api.post(reqUrl, paramsRequest);
    console.log("onDuplicateChetKhau", res.data.data);
    setChietKhau(null);
    loadListChietKhau();
  };

  return (
    <>
      <SimpleHeader
        name={`Thông tin chi tiết Partner: #${params.id}`}
        parentName={`Thông tin chi tiết Partner: #${params.id}`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    Thông tin chi tiết Partner: {params.id}
                  </h3>
                </CardHeader>
                <CardBody>
                  {data ? (
                    <Form>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Tên
                        </label>
                        <Input
                          defaultValue={data.name}
                          id="example-number-input"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Partner ID
                        </label>
                        <Input
                          defaultValue={data.partner_id}
                          id="example-number-input"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Partner Key
                        </label>
                        <Input
                          defaultValue={data.partner_key}
                          id="example-number-input"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Balance
                        </label>
                        <Input
                          defaultValue={data.balance.toLocaleString()}
                          id="example-number-input"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Thời gian đăng ký
                        </label>
                        <Input
                          defaultValue={data.createdAt.toLocaleString("fr-BE")}
                          id="example-number-input"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Trạng thái
                        </label>
                        <Input
                          defaultValue={
                            data.status ? "Đang hoạt động" : "Tạm ngưng"
                          }
                          id="example-number-input"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Form>
                  ) : (
                    <p>Đang tải dữ liệu</p>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    Danh sách chiết khấu:{" "}
                    {chiet_khau ? chiet_khau.pagination.total : 0} record.
                    {chiet_khau && chiet_khau.pagination.total > 0 ? (
                      <></>
                    ) : (
                      <Button
                        className="btn-icon ml-2"
                        color="primary"
                        type="button"
                        onClick={onDuplicateChetKhau}
                      >
                        <span className="btn-inner--text">Tạo Chiết khấu</span>
                      </Button>
                    )}
                  </h3>
                </CardHeader>
                <CardBody>
                  <div className="py-4 table-responsive">
                    <Row className="mb-5">
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="end_date"
                        >
                          Loại dịch vụ
                        </label>
                        <Select2
                          className={`form-control`}
                          options={{
                            placeholder: "Chọn Dịch vụ",
                          }}
                          value={service}
                          onChange={(e) => setService(e.target.value)}
                          defaultValue={service}
                          data={[
                            { id: 0, text: "Tất cả" },
                            { id: 1, text: "Thẻ Cào" },
                            { id: 2, text: "TOPUP" },
                          ]}
                        />
                      </Col>
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="end_date"
                        >
                          Nhà mạng
                        </label>
                        <Select2
                          className={`form-control`}
                          options={{
                            placeholder: "Chọn nhà mạng",
                          }}
                          value={telco}
                          onChange={(e) => setTelco(e.target.value)}
                          defaultValue={telco}
                          data={[
                            { id: 0, text: "Tất cả" },
                            { id: 1, text: "Mobifone" },
                            { id: 2, text: "Viettel" },
                            { id: 3, text: "Vinaphone" },
                          ]}
                        />
                      </Col>
                      <Col lg="4">
                        <label className="form-control-label" htmlFor="btn">
                          &nbsp;
                        </label>
                        <Button
                          className="btn-icon ml-2 form-control"
                          color="primary"
                          type="button"
                          onClick={onSearchChange}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">Search</span>
                        </Button>
                      </Col>
                    </Row>
                    {chiet_khau != null && chiet_khau.data.length > 0 ? (
                      <BootstrapTable
                        remote
                        keyField="id"
                        data={chiet_khau.data}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        filter={filterFactory()}
                        pagination={paginationFactory({
                          page: pageCurrent,
                          sizePerPage: sizePage,
                          totalSize: totalItem,
                        })}
                        onTableChange={onTableChange}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <div className="clearAll"></div>
        </Row>
      </Container>
    </>
  );
};

export default PartnerDetail;
