// reactstrap components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
// react plugin that creates text editor
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "dropzone";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import ToastProvider from "hooks/useToastProvider";

import { createWorker } from "tesseract.js";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import { QrReader } from "react-qr-reader";

Dropzone.autoDiscover = false;

const formSchema = Yup.object().shape({
  code: Yup.string().required("Mã nạp không được trống"),
  serial: Yup.string().required("Mã Serial không được trống"),
});

const NhapKhoTheGiay = () => {
  const [valueAgency, setValueAgency] = useState(6);
  const [valuePrice, setValuePrice] = useState(0);
  const [valueNetworkOperator, setValueNetworkOperator] = useState("Viettel");
  const [agencyList, setAgencyList] = useState([]);
  const [telcoList, setTelcoList] = useState([]);
  const [menhGiaList, setMenhGiaList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stringAlert, setStringAlert] = useState("");
  const [product, setProduct] = useState([]);
  const [code, setCode] = useState("");
  const [serial, setSerial] = useState("");

  const [texResult, setTexResult] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [expriredDate, setExpriredDate] = useState("");

  const delay = 10;
  const constraints = {
    facingMode: "environment",
    focusMode: "continuous",
    zoom: 2.0,
    frameRate: { max: 30 },
  };

  let userAdmin = useSelector(getAdminData);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const registerRs = (fieldName, options = {}) => {
    const registeredField = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };

  const handleSubmitForm = async (value, e) => {
    e.preventDefault();
    if (valueAgency == 0) {
      ToastProvider("error", "Vui lòng chọn Agency");
    } else if (valueNetworkOperator == 0) {
      ToastProvider("error", "Vui lòng chọn nhà mạng");
    } else if (valuePrice == 0) {
      ToastProvider("error", "Vui lòng chọn mệnh giá");
    } else if (code == "") {
      ToastProvider("error", "Vui lòng nhập mã thẻ");
    } else if (serial == "") {
      ToastProvider("error", "Vui lòng nhập mã serial");
    } else {
      try {
        //console.log("handleSubmitForm", valueAgency, valueNetworkOperator, valuePrice, value.quantity);
        setIsLoading(true);
        const reqUrl = "/admin/nhap-kho-card-manual";
        const params = {};
        params.agency_id = valueAgency;
        params.telco_id = valueNetworkOperator;
        params.menh_gia_id = valuePrice;
        params.serial = serial;
        params.code = code;
        params.exprired_date = expriredDate;
        const res = await Api.post(reqUrl, params);
        console.log("handleSubmitForm", res, res.data);
        if (res.data.status) {
          ToastProvider("success", "Nhập kho thành công");
          setStringAlert(
            `Nhập kho thành công: Tổng: ${res.data.data.length}, Lưu ${res.data.count_done}, Trùng ${res.data.count_fail}`
          );
        } else {
          ToastProvider("error", res.data.message);
          setStringAlert(res.data.message);
          setIsLoading(false);
        }
      } catch (error) {
        ToastProvider("error", "Có lỗi xảy ra vui lòng thử lại");
        setStringAlert("Có lỗi xảy ra vui lòng thử lại");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getListAgency();
    getListProductServiceAgency();
    setValuePrice(0);
    setValueNetworkOperator(0);
  }, []);

  useEffect(() => {
    getListProductServiceAgency();
    setValuePrice(0);
    setValueNetworkOperator(0);
  }, [valueAgency]);

  useEffect(() => {
    let menhgia = [];
    if (product[valueNetworkOperator] != undefined) {
      for (const tmp of product[valueNetworkOperator].menh_gia) {
        if (tmp != undefined) {
          menhgia.push({
            id: tmp.id,
            text: tmp.name + " (" + tmp.chietkhau_admin + "%)",
          });
        }
      }
      setValuePrice(0);
      setMenhGiaList(menhgia);
    }

    console.log("valueNetworkOperator", valueNetworkOperator);
  }, [valueNetworkOperator]);

  const getListAgency = async () => {
    const reqUrl = "/admin/get-list-agency";
    const params = { token: userAdmin.token_login };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      let tmp = [];
      for (const it of res.data.data.data) {
        tmp.push({
          id: it.id,
          text: it.name + " (" + it.balance.toLocaleString() + " đ)",
          balance: it.balance,
        });
      }
      setAgencyList(tmp);
    }
  };

  const getListProductServiceAgency = async () => {
    const reqUrl = "/admin/get-list-product-agency-service";
    const params = {
      token: userAdmin.token_login,
      agency_id: valueAgency,
      service_id: 1,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      let tmp = [];
      for (const it of res.data.data) {
        if (!tmp[it.telco.id]) {
          tmp[it.telco.id] = it.telco;
          tmp[it.telco.id].menh_gia = [it.menh_gia];
          tmp[it.telco.id].menh_gia[0].chietkhau_admin = it.chietkhau_admin;
        } else {
          let isHas = false;
          for (let i = 0; i < tmp[it.telco.id].menh_gia.length; i++) {
            if (tmp[it.telco.id].menh_gia[i].id == it.menh_gia.id) {
              isHas = true;
              break;
            }
          }

          if (!isHas) {
            tmp[it.telco.id].menh_gia.push({
              ...it.menh_gia,
              chietkhau_admin: it.chietkhau_admin,
            });
          }
        }
      }
      let telco = [];
      for (const tmp1 of tmp) {
        if (tmp1 != undefined) {
          telco.push({ id: tmp1.id, text: tmp1.name });
        }
      }
      setTelcoList(telco);
      setProduct(tmp);
    }
  };

  const convertImageToText = useCallback(async () => {
    if (!selectedImage) return;

    const worker = await createWorker();
    await worker.loadLanguage("eng");
    await worker.initialize("eng");
    const { data } = await worker.recognize(selectedImage);
    console.log(data);
    console.log(data.text);
    setTexResult(data.text);
    await worker.terminate();
  }, [selectedImage]);

  useEffect(() => {
    //convertImageToText();
    parserText();
  }, [selectedImage, convertImageToText]);

  function handleTakePhoto(dataUri) {
    setTexResult("");
    setSelectedImage(null);
    setSelectedImage(dataUri);
  }

  const handleChangeImage = (e) => {
    setTexResult("");
    setSelectedImage(null);
    if (e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const parserText = () => {
    if (!selectedImage) return;

    setTexResult("Đang xử lý");
    var myHeaders = new Headers();
    myHeaders.append("apikey", "K84196557788957");

    var formdata = new FormData();
    formdata.append("file", selectedImage, "a.png");
    formdata.append("language", "eng");
    formdata.append("isOverlayRequired", "true");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.ocr.space/Parse/Image", requestOptions)
      .then(async (response) => {
        let result = await response.text();
        setTexResult(result);
        result = JSON.parse(result);
        if (!result.IsErroredOnProcessing) {
          const lines = result.ParsedResults[0].TextOverlay.Lines;
          let ma_the = "";
          let ma_serial = "";

          for (const line of lines) {
            const LineText = line.LineText;
            console.log("line", line);
            let txt = LineText;
            let num = 0;
            //515005 22599978
            console.log("check", txt);
            txt = txt.replace(/ /g, "");
            txt = txt.replace(/-/g, "");
            num = parseInt(txt);
            console.log("check", num);
            console.log("length", String(num).length);
            if (!isNaN(Number(num))) {
              console.log("HOP LE");
              let str = String(num);
              if (String(txt.charAt(0)) == "0") {
                str = "0" + str;
              }
              if (valueNetworkOperator == 1) {
                //Mobifone
                if (str.length == 12) {
                  ma_the = str;
                } else if (str.length == 15) {
                  ma_serial = str;
                }
              } else if (valueNetworkOperator == 2) {
                //Viettel
                if (str.length == 15) {
                  ma_the = str;
                } else if (str.length == 14) {
                  ma_serial = str;
                }
              } else if (valueNetworkOperator == 3) {
                //Vinaphone
                if (str.length == 14) {
                  if (ma_the == "") {
                    ma_the = str;
                  } else {
                    if (ma_serial == "") {
                      ma_serial = str;
                    }
                  }
                }
              }
            }
          }
          setSerial(ma_serial);
          setCode(ma_the);
          console.log("ma_the", ma_the);
          console.log("ma_serial", ma_serial);
        }
        console.log("response", result);
      })
      .then((result) => {
        console.log("result", result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleScan = (data) => {
    if (data != null) {
      const text = data?.text;
      if (text) {
        const tmp = text.split(" ");
        console.log(tmp);
        if (tmp.length == 3) {
          setSerial(tmp[0].replace(/-/g, ""));
          setCode(tmp[1].replace(/-/g, ""));
        }
      }
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <>
      <SimpleHeader name="Nhập kho" parentName="Nhập kho" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Nhập Kho Thẻ Giấy</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/* --------------SelectBox Agency--------- */}
                    {/* <FormGroup className="selected-form">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn Agency
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Agency",
                        }}
                        value={valueAgency}
                        onChange={(e) => setValueAgency(e.target.value)}
                        data={agencyList}
                      />
                      <FormFeedback>{errors?.selectBox?.message}</FormFeedback>
                    </FormGroup> */}
                    {/* --------------SelectBox Nhà Mạng--------- */}
                    <FormGroup className="selected-form">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn Nhà Mạng
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Nhà Mạng",
                        }}
                        value={valueNetworkOperator}
                        onChange={(e) =>
                          setValueNetworkOperator(e.target.value)
                        }
                        data={telcoList}
                      />
                      <FormFeedback>
                        {errors?.selectNetworkOperator?.message}
                      </FormFeedback>
                    </FormGroup>
                    {/* --------------SelectBox Mệnh Giá--------- */}
                    <FormGroup className="selected-form">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Chọn Mệnh Giá
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Mệnh Giá",
                        }}
                        value={valuePrice}
                        onChange={(e) => setValuePrice(e.target.value)}
                        data={menhGiaList}
                      />
                      <FormFeedback>{errors?.selectBox?.message}</FormFeedback>
                    </FormGroup>
                    {/* ---------------------Input Text-------------- */}
                    <FormGroup>
                      <label className="form-control-label" htmlFor="code">
                        Mã thẻ
                      </label>
                      <Input
                        {...registerRs("code")}
                        value={code}
                        name="code"
                        type="text"
                        min={0}
                        className={`${errors.code ? "isError" : ""}`}
                        onChange={(e) => setCode(e.target.value)}
                        invalid={errors.code ? true : false}
                      />
                      <FormFeedback>{errors?.code?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="serial">
                        Số Serial
                      </label>
                      <Input
                        {...registerRs("serial")}
                        value={serial}
                        name="serial"
                        type="text"
                        min={0}
                        className={`${errors.serial ? "isError" : ""}`}
                        onChange={(e) => setSerial(e.target.value)}
                        invalid={errors.serial ? true : false}
                      />
                      <FormFeedback>{errors?.serial?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="serial">
                        Ngày hết hạn
                      </label>
                      <Input
                        defaultValue={expriredDate}
                        id="start_date"
                        type="date"
                        onChange={(e) => setExpriredDate(e.target.value)}
                      />
                      <FormFeedback>{errors?.serial?.message}</FormFeedback>
                    </FormGroup>
                    {valueNetworkOperator == 10 ? (
                      <FormGroup className="selected-form">
                        <label htmlFor="upload">Scan Mã QRCode</label>
                        <QrReader
                          scanDelay={delay}
                          onError={handleError}
                          onResult={handleScan}
                          constraints={constraints}
                          chooseDeviceId={3}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup className="selected-form">
                        <label htmlFor="upload">Chụp hình thẻ cào</label>
                        <div className={`custom-file`}>
                          <input
                            className="custom-file-input"
                            type="file"
                            id="upload"
                            capture="environment"
                            accept="image/*"
                            onChange={handleChangeImage}
                          />
                        </div>
                        <Input
                          value={texResult}
                          id="example-number-input"
                          type="textarea"
                          readOnly
                        />
                      </FormGroup>
                    )}
                    <div className="mt-3 d-flex d-text align-items-md-end justify-content-md-end">
                      {isLoading ? (
                        <p>{stringAlert ? stringAlert : "Đang xử lý"}</p>
                      ) : (
                        <Button
                          className="btn-icon"
                          color="primary"
                          type="button"
                          onClick={handleSubmit(handleSubmitForm)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">Nhập kho</span>
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NhapKhoTheGiay;
